import '../styles/index.scss';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';



  const swiper = new Swiper('.product-carousel.swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    speed: 800,
    centeredSlides: true,
    autoplay: {
      delay: 6000, // 6000ms = 6 seconds
      disableOnInteraction: true, // stop autoplay running even after interaction (e.g. swipe)
    },
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
